export const SET_DATERANGE = "[REVENUES] SET DATERANGE";
export const SET_DATEGROUP = "[REVENUES] SET DATEGROUP";
export const SET_GROUPBY = "[REVENUES] SET GROUPBY";
export const SET_USERNAME_FILTER = "[REVENUES] SET USERNAME FILTER";
export const SET_GAME_FILTER = "[REVENUES] SET GAME FILTER";
export const SET_PLATFORM_FILTER = "[REVENUES] SET PLATFORM FILTER";
export function setDateRange(startDate, endDate) {
  return {
    type: SET_DATERANGE,
    payload: { startDate, endDate },
  };
}

export function setDateGroup(dateGroup) {
  return {
    type: SET_DATEGROUP,
    payload: { dateGroup },
  };
}

export function setGameFilter(game) {
  return {
    type: SET_GAME_FILTER,
    payload: { game },
  };
}

export function setPlatformFilter(platform) {
  return {
    type: SET_PLATFORM_FILTER,
    payload: { platform },
  };
}

export function setUsernameFilter(username) {
  return {
    type: SET_USERNAME_FILTER,
    payload: { username },
  };
}

export function setGroupBy(groupBy) {
  return {
    type: SET_GROUPBY,
    payload: { groupBy },
  };
}
