import "./react_dates_overrides.css";
import _ from "@lodash";

import * as Actions from "./store/actions";

import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  FormGroup,
  Input,
  Row,
  Spinner,
  Collapse,
  Label,
} from "reactstrap";
import React, { Component } from "react";

import { DateRangePicker } from "react-dates";
import { connect } from "react-redux";
import reducer from "./store/reducers";
import withReducer from "app/store/withReducer";

class GameStats extends Component {
  constructor(props) {
    super(props);
    this.state = {
      focusedInput: null,
      startDate: null,
      endDate: null,
      isOpenGroupBy: false,
      groupBy: ["game_name", "created_at_date"],
      sizePerPage: 10,
    };
  }

  componentDidMount() {
    const {
      gamestats: { startDate, endDate },
    } = this.props;
    this.setState({ startDate, endDate }, () => {
      this.props.dispatch(Actions.getStats());
    });
  }

  loading = () => (
    <div className="animated fadeIn pt-1 text-center">Loading...</div>
  );
  checkGroupBy = async (event) => {
    let groupBy = _.cloneDeep(this.state.groupBy);
    if (event.target.checked) {
      groupBy.push(event.target.id);
    } else {
      groupBy.splice(groupBy.indexOf(event.target.id), 1);
    }

    this.setState({ groupBy: groupBy });
  };

  toggleGroupBy = () => {
    this.setState({ isOpenGroupBy: !this.state.isOpenGroupBy });
  };
  toCommasFormat = (num) => {
    return num ? num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : "0";
  };
  priceFormatter = (cell, row) => {
    return `$ ${this.toCommasFormat(cell?.toFixed(2))}`;
  };
  minutesFormatter = (cell, row) => {
    return `${this.toCommasFormat(cell?.toFixed(0))}`;
  };

  onApply = () => {
    if (!this.state.groupBy.length) {
      return;
    }
    this.props.dispatch(
      Actions.getDataOnApply(
        this.state.startDate,
        this.state.endDate,
        this.state.groupBy
      )
    );
  };

  getTableKey = (data) => {
    if (data[0].name) {
      return "name";
    } else if (data[0].category) {
      return "category";
    } else if (data[0].created_at_date) {
      return "created_at_date";
    } else if (data[0].country_code) {
      return "country_code";
    } else if (data[0].games_amount) {
      return "games_amount";
    } else if (data[0].platform) {
      return "platform";
    }
  };

  getListForFilter = (data, key) => {
    return data.length > 0
      ? [
          ...new Set(
            data.map((d) => {
              return d[key];
            })
          ),
        ].reduce(function (o, val) {
          o[val] = val;
          return o;
        }, {})
      : {};
  };

  onSizePerPageList(sizePerPage) {
    this.setState({ sizePerPage: sizePerPage });
  }

  render() {
    const { loading } = this.props;
    if (loading) {
      return loading();
    }
    const {
      gamestats: {
        data: { gamestats },
        loading: stats_loading,
      },
    } = this.props;

    return (
      <div className="animated fadeIn">
        <Row>
          <Col>
            <Card>
              <CardHeader>
                <h3>Game Stats</h3>
                <FormGroup className="mb-0">
                  <DateRangePicker
                    minimumNights={0}
                    isOutsideRange={() => false}
                    startDate={this.state.startDate}
                    startDateId="startDate"
                    endDate={this.state.endDate}
                    endDateId="endDate"
                    small
                    onDatesChange={({ startDate, endDate }) =>
                      this.setState({ startDate, endDate })
                    }
                    focusedInput={this.state.focusedInput}
                    onFocusChange={(focusedInput) => {
                      this.setState({ focusedInput });
                    }}
                    orientation={this.state.orientation}
                    openDirection={this.state.openDirection}
                  />
                </FormGroup>

                <div className="mt-2">
                  <Button color="primary" onClick={this.toggleGroupBy}>
                    Group By
                    <div
                      className={
                        "ml-2 collapsed-arrow " +
                        (this.state.isOpenGroupBy
                          ? "collapsedArrowShow"
                          : "collapsedArrowHide")
                      }
                    />
                  </Button>
                  <Collapse isOpen={this.state.isOpenGroupBy}>
                    <Card className="mt-1 mb-0">
                      <CardBody>
                        <FormGroup check inline>
                          <Input
                            id="game_name"
                            name="check"
                            type="checkbox"
                            onChange={this.checkGroupBy}
                            checked={
                              this.state.groupBy.indexOf("game_name") !== -1
                            }
                          />
                          <Label check for="game_name">
                            Name
                          </Label>
                        </FormGroup>
                        <FormGroup check inline>
                          <Input
                            id="created_at_date"
                            name="check"
                            type="checkbox"
                            onChange={this.checkGroupBy}
                            checked={
                              this.state.groupBy.indexOf("created_at_date") !==
                              -1
                            }
                          />
                          <Label check for="created_at_date">
                            Date
                          </Label>
                        </FormGroup>
                        <FormGroup check inline>
                          <Input
                            id="games_amount"
                            name="check"
                            type="checkbox"
                            onChange={this.checkGroupBy}
                            checked={
                              this.state.groupBy.indexOf("games_amount") !== -1
                            }
                          />
                          <Label check for="games_amount">
                            Games Amount
                          </Label>
                        </FormGroup>
                        <FormGroup check inline>
                          <Input
                            id="game_gamecategory_name"
                            name="check"
                            type="checkbox"
                            onChange={this.checkGroupBy}
                            checked={
                              this.state.groupBy.indexOf(
                                "game_gamecategory_name"
                              ) !== -1
                            }
                          />
                          <Label check for="game_gamecategory_name">
                            Categories
                          </Label>
                        </FormGroup>
                        <FormGroup check inline>
                          <Input
                            id="country_code"
                            name="check"
                            type="checkbox"
                            onChange={this.checkGroupBy}
                            checked={
                              this.state.groupBy.indexOf("country_code") !== -1
                            }
                          />
                          <Label check for="country_code">
                            Country
                          </Label>
                        </FormGroup>
                        <FormGroup check inline>
                          <Input
                            id="platform"
                            name="check"
                            type="checkbox"
                            onChange={this.checkGroupBy}
                            checked={
                              this.state.groupBy.indexOf("platform") !== -1
                            }
                          />
                          <Label check for="platform">
                            Platform
                          </Label>
                        </FormGroup>
                      </CardBody>
                    </Card>
                  </Collapse>
                </div>

                <Button
                  color={
                    (!stats_loading && this.state.groupBy.length > 0) ||
                    stats_loading
                      ? "outline-success"
                      : "outline-danger"
                  }
                  className="mt-2"
                  disabled={stats_loading || !this.state.groupBy.length}
                  onClick={(e) => {
                    this.onApply();
                  }}
                >
                  {stats_loading && <Spinner color="success" size="sm" />}
                  {!stats_loading && this.state.groupBy.length > 0 && (
                    <i className="icon-check"></i>
                  )}
                  {!stats_loading && !this.state.groupBy.length && (
                    <i className="icon-close"></i>
                  )}{" "}
                  Apply
                </Button>
              </CardHeader>
              <CardBody>
                {stats_loading ? (
                  <div className="text-center">Loading data</div>
                ) : gamestats.length === 0 ? (
                  <div className="text-center">Empty</div>
                ) : (
                  <BootstrapTable
                    trClassName="customBootstrapTable"
                    data={stats_loading ? [] : gamestats}
                    remote={false}
                    pagination={true}
                    striped
                    options={{
                      defaultSortName: "downloads",
                      defaultSortOrder: "desc",
                      clearSearch: true,
                      sizePerPageList: [10, 20, 50, 100],
                      sizePerPage: this.state.sizePerPage,
                      onSizePerPageList: this.onSizePerPageList.bind(this),
                    }}
                  >
                    <TableHeaderColumn
                      dataField={this.getTableKey(gamestats)}
                      isKey
                      hidden
                    >
                      ID
                    </TableHeaderColumn>
                    <TableHeaderColumn
                      ref="name"
                      width="250px"
                      dataField="name"
                      dataSort={false}
                      filter={{ type: "TextFilter" }}
                      hidden={
                        !(
                          !stats_loading &&
                          gamestats.length > 0 &&
                          gamestats[0].name
                        )
                      }
                    >
                      Game
                    </TableHeaderColumn>
                    <TableHeaderColumn
                      ref="category"
                      dataField="category"
                      dataSort={false}
                      filter={{
                        type: "SelectFilter",
                        options: this.getListForFilter(gamestats, "category"),
                        delay: 1000,
                      }}
                      hidden={
                        !(
                          !stats_loading &&
                          gamestats.length > 0 &&
                          gamestats[0].category
                        )
                      }
                    >
                      Category
                    </TableHeaderColumn>
                    <TableHeaderColumn
                      ref="created_at_date"
                      dataField="created_at_date"
                      dataSort={true}
                      hidden={
                        !(
                          !stats_loading &&
                          gamestats.length > 0 &&
                          gamestats[0].created_at_date
                        )
                      }
                    >
                      Date
                    </TableHeaderColumn>
                    <TableHeaderColumn
                      ref="country_code"
                      dataField="country_code"
                      dataSort={false}
                      hidden={
                        !(
                          !stats_loading &&
                          gamestats.length > 0 &&
                          gamestats[0].country_code
                        )
                      }
                      filter={{
                        type: "SelectFilter",
                        options: this.getListForFilter(
                          gamestats,
                          "country_code"
                        ),
                        delay: 1000,
                      }}
                    >
                      Country Code
                    </TableHeaderColumn>

                    <TableHeaderColumn
                      ref="platform"
                      dataField="platform"
                      dataSort={false}
                      hidden={
                        !(
                          !stats_loading &&
                          gamestats.length > 0 &&
                          gamestats[0].platform
                        )
                      }
                      filter={{
                        type: "SelectFilter",
                        options: this.getListForFilter(gamestats, "platform"),
                        delay: 1000,
                      }}
                    >
                      Platform
                    </TableHeaderColumn>
                    <TableHeaderColumn
                      ref="games_amount"
                      dataField="games_amount"
                      dataSort={true}
                      hidden={
                        !(
                          !stats_loading &&
                          gamestats.length > 0 &&
                          gamestats[0].games_amount
                        )
                      }
                    >
                      Games Amount
                    </TableHeaderColumn>
                    <TableHeaderColumn
                      ref="downloads"
                      dataField="downloads"
                      dataSort={true}
                    >
                      Downloads
                    </TableHeaderColumn>
                    <TableHeaderColumn
                      ref="revenue"
                      dataSort={true}
                      dataField="revenue"
                      dataFormat={this.priceFormatter}
                    >
                      Revenue
                    </TableHeaderColumn>
                    <TableHeaderColumn
                      ref="minutes"
                      dataField="minutes"
                      dataSort={true}
                      dataFormat={this.minutesFormatter}
                    >
                      Minutes
                    </TableHeaderColumn>
                    <TableHeaderColumn
                      ref="purchases"
                      dataField="purchases"
                      dataSort={true}
                    >
                      Purchases
                    </TableHeaderColumn>
                  </BootstrapTable>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    gamestats: state.gamestats.gamestats,
  };
};

export default withReducer(
  "gamestats",
  reducer
)(connect(mapStateToProps)(GameStats));
